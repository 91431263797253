import {
  ActionFunction,
  redirect,
  type LinksFunction,
  type MetaFunction,
} from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import favicon from '../public/favicon.svg';
import { Layout } from './components';
import GlobalStyles from './styles/globalStyles';
import {CART_QUERY} from '~/utils/queries/cart';
import { sanityFetch } from './utils/api/sanity';
import { Suspense, useEffect } from 'react';
import { gdprConsent } from './cookies';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {rel: 'icon', type: 'image/svg+xml', href: favicon},
  ];
};

async function getCart({storefront}: any, cartId: any) {
  if (!storefront) {
    throw new Error('missing storefront client in cart query');
  }

  const {cart} = await storefront.query(CART_QUERY, {
    variables: {
      cartId,
      country: storefront.i18n.country,
      language: storefront.i18n.language,
    },
    cache: storefront.CacheNone(),
  });
  return cart;
}

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
  "og:site_name": "Lepa Vida",
  "og:url": "https://lepavidawine.co.uk",
});

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await gdprConsent.parse(cookieHeader)) || {};

  if (formData.get("accept-gdpr") === "true") {
    cookie.gdprConsent = true;
  }

  return redirect("/", {
    headers: {
      "Set-Cookie": await gdprConsent.serialize(cookie)
    }
  });
};

export async function loader({context, request}: any) {
  const cartId = await context.session.get('cartId');

  const cart = cartId
    ? (
        await context.storefront.query(CART_QUERY, {
          variables: {
            cartId,
            country: context.storefront.i18n.country,
            language: context.storefront.i18n.language,
          },
          cache: context.storefront.CacheNone(),
        })
      ).cart
    : null;

  const settings = await sanityFetch(`*[_type == "settings"][0]`);
  const linksRefs: string[] = [];
  (settings && settings.menu && settings.menu.links) && settings.menu.links.map((link: any) => {
    linksRefs.push (link.reference._ref)
  })
  const menuImageRef = settings.menuImage.asset._ref;
  // const linkTitles: string[] = (settings && settings.menu && settings.menu.links) && settings.menu.links.map((link: any) => link.title)
  const query = linksRefs.map((ref: string) => ` _id == "${ref}" `).join('||') 
  // const links = await sanityFetch(`*[_type == "page" && ${query}]{title, slug {current}} | order(priority desc)`)
  const links = Promise.all(linksRefs.map(async (ref: string) => {
    const link = await sanityFetch(`*[_type == "page" && _id=="${ref}"]{title, slug {current}}`)
    return link
  }))
  const linkSlugs = await links && (await links).map((slug: any) => ({title: slug[0].title, slug: slug[0].slug.current}));

  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await gdprConsent.parse(cookieHeader)) || {};

  return {cart, linkSlugs, menuImageRef, showGdprBanner: !cookie.gdprConsent};
}

export default function App() {
  const data = useLoaderData();
  useEffect(() => {
    let scriptSrc = document.createElement('script');
    let scriptLog = document.createElement('script');
    let clarity = document.createElement('script');
    let hubSpot = document.createElement('script');
    let adds = document.createElement('script');

    adds.innerHTML = `
    function loadScript(c,b){var a=document.createElement("script");a.type="text/javascript";a.readyState?a.onreadystatechange=function(){if("loaded"==a.readyState||"complete"==a.readyState)a.onreadystatechange=null,b()}:a.onload=function(){b()};a.src=c;document.getElementsByTagName("head")[0].appendChild(a)};loadScript("https://prod.api.adline.com/api/cpa?key=UID-MoeDs8YGF2WWw02ds9nR", function(){});
    `


    scriptSrc.setAttribute('async', "");
    scriptSrc.setAttribute('src', "https://www.googletagmanager.com/gtag/js?id=G-Y1QQ3E3R71");

    hubSpot.setAttribute('id', "hs-script-loader");
    hubSpot.setAttribute('type', "text/javascript");
    hubSpot.setAttribute('async', "");
    hubSpot.setAttribute('defer', "");
    hubSpot.setAttribute('src', "//js-na1.hs-scripts.com/6771619.js");

    scriptLog.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-Y1QQ3E3R71');`

    clarity.innerHTML = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hplv49b3rf");
    `
    clarity.setAttribute('type', 'text/javascript')
    if (!data.showGdprBanner) {
      document.head.appendChild(adds);
      document.head.appendChild(scriptSrc);
      document.head.appendChild(scriptLog);
      document.head.appendChild(clarity);
      document.head.appendChild(hubSpot);
    }
  }, [])
  return (
    <html lang="en" suppressHydrationWarning={true}>
      <head>
        <Meta />
        <Links />
        {typeof document === "undefined" ? "__STYLES__" : null}
      </head>
      <body>
        <GlobalStyles/>
        <Layout>
          <Outlet />
          <ScrollRestoration />
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

export function Head() {
  return (
    <>
      <Meta />
      <Links />
      {typeof document === "undefined" ? "__STYLES__" : null}
    </>
  );
}